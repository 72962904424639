var uploadtimer;

$.ready.then(function() {
	

    $('.review-slider').owlCarousel({
        loop:false, 
        // margin:10,
        nav:false,
        dots: false,
        responsive:{ 
            0:{
                items:1,
                dots: true,
            },
            600:{
                items:1,
                dots: true
            },
            1000:{
                items:3 
            }
        },
        addClassActive: true,
        navText : ['<i class="far fa-chevron-left"></i>','<i class="far fa-chevron-right"></i>']
        }
    ); 
	 
	setBestelStappen();
	
	$('body').on('click','.show-specificaties',function(){
		$(this).closest('.order-line').find('.spec-details').toggleClass('spec-hidden');
		$(this).closest('.order-line').find('.spec-upload').toggleClass('spec-visible');

		if(!$(this).closest('.order-line').find('.spec-details').hasClass('spec-hidden')){
			$(this).find('.far').removeClass('fa-chevron-down').addClass('fa-chevron-up');
		} else {
			$(this).find('.far').removeClass('fa-chevron-up').addClass('fa-chevron-down');
		}

		return false;
	});
	 
	$('._file-uploadbutton').each(function(){
		var button = $(this);
		var fileUploader = new plupload.Uploader({
			runtimes : 'html5',
			browse_button : button[0], 
			drop_element : button[0],
			url : $('base').attr('href')+'xhr/upload-order.php',
			multi_selection : true,
			multipart_params : {fields:button.closest('_uploader-container').find(':input').serialize(),id:button.closest('_uploader-container').data('id')},
			filters : {
				max_file_size : '100mb',
				mime_types: [
					{title : "Image files", extensions : "jpg,jpeg,gif,png,ai,eps,psd,bmp,indd"},
					{title : "Document files", extensions : "pdf"}
				]
			},
			init: {
				PostInit: function() {		
					
				},
				FilesAdded: function(up, files) {					
					var container = button.closest('.order-line').find('.uploaded-files');
					$(".file-collapse").removeClass('-textred');
					/* if (up.files.length > 1) {
						up.splice(0,up.files.length-1);
					} */
				 
					//container.html('');
					$.each(files, function(i,file) {
						container.append('<div class="_upload-line file-uploadfile mb-2" id="' + file.id + '"><div class="row"><div class="col-9">' + file.name + ' &nbsp; <b></b></a></div><div class="col-3 d-none"><a href="#" class="_del-order-file"><img src="images/trash.png" alt="Verwijderen"></a></div></div></div>');
					});
					
					button.children('.meer').removeClass('d-none').siblings('.first').remove();
					fileUploader.start();
				},
				UploadProgress: function(up, file) {
					document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
				},
				BeforeUpload: function(up,file){
					fileUploader.settings.multipart_params = {fields:button.closest('._uploader-container').find(':input').serialize(),id:button.closest('._uploader-container').data('id')};	
				},
				FileUploaded: function(up,file,result){
					$('#'+file.id).closest('._upload-collapse').find('._btn-klaar').removeClass('d-none');
					$('#'+file.id).find('b').replaceWith('<i class="fas ml-2 fa-check checked -text-green"></i>');
					$('#'+file.id).find('.d-none').removeClass('d-none');
					$('#'+file.id).find('._del-order-file').attr("data-id", ""+result.response);
				},
				Error: function(up,error){
					alert(error.message);	
				}
			},
		});
		fileUploader.init();
	});

	$('._my_file-upload').each(function(){ 
		var button = $(this);
		var bestandUploader = new plupload.Uploader({
			runtimes : 'html5',
			browse_button : button[0], 
			drop_element : button[0],
			url : $('base').attr('href')+'xhr/upload-order.php',
			multi_selection : true,
			multipart_params : {fields:button.closest('._my_file_uploader-container').find(':input').serialize(),id:button.closest('._my_file_uploader-container').data('id')},
			filters : {
				max_file_size : '100mb',
				mime_types: [
					{title : "Image files", extensions : "jpg,jpeg,gif,png,ai,eps,psd,bmp,indd"},
					{title : "Document files", extensions : "pdf"}
				]
			},
			init: {
				PostInit: function() {		
					
				},
				FilesAdded: function(up, files) {					 
					var container = button.closest('tr').find('.uploaded-files'); 
					// $(".file-collapse").removeClass('-textred');
					/* if (up.files.length > 1) {
						up.splice(0,up.files.length-1);
					} */
				 
					//container.html('');
					$.each(files, function(i,file) {
						container.append('<div class="_upload-line file-uploadfile position-relative" style="min-height:45px;" id="' + file.id + '"><div style="white-space: nowrap; overflow:hidden; text-overflow: ellipsis; max-width:150px;">' + file.name + '</div><div class="float-right _bestand-icons position-absolute " style="top:0px; right:0px;"> <b></b><a href="#" class="_my_file-upload-proxy hover-green ml-2" title="Meer uploaden" style="position: relative; z-index: 1;"><i class="fal fa-plus"></i></a><a href="#" class="d-none float-right _del-order-file mx-2 hover-red" title="Verwijderen"><i class="fal fa-times"></i></a></div>'
						); 
					}); 
					 
					// button.children('.meer').removeClass('d-none').siblings('.first').remove(); 
					bestandUploader.start();  
				},
				UploadProgress: function(up, file) {
					document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span class="mr-2">' + file.percent + "%</span>";
				},
				BeforeUpload: function(up,file){
					bestandUploader.settings.multipart_params = {fields:button.closest('._my_file_uploader-container').find(':input').serialize(),id:button.closest('._my_file_uploader-container').data('id')};	
				},
				FileUploaded: function(up,file,result){
					$('#'+file.id).closest('._upload-collapse').find('._btn-klaar').removeClass('d-none');
					$('#'+file.id).find('b').replaceWith('<i class="fal ml-2 fa-check checked -text-green"></i>');
					$('#'+file.id).closest('.uploaded-files').find('.d-none').removeClass('d-none');
					$('#'+file.id).find('._del-order-file').attr("data-id", ""+result.response); 

					$('._my_file_uploader-container[data-id="'+button.closest('._my_file_uploader-container').data('id')+'"]').hide();
				},
				Error: function(up,error){
					alert(error.message);	
				}
			},
		});
		bestandUploader.init();
	});

	$("body").on('click', '._my_file-upload-proxy', function(e){
		e.preventDefault();

		var id = $(this).closest('.order-line').data('id');
		$('._my_file_uploader-container[data-id="'+id+'"]').find('.moxie-shim input').trigger('click');
	});

	$("body").on('click','._open-modal',function(e){
		e.preventDefault();

		if($(this).data('modalname')){
			var modalname = $(this).data('modalname');
			$('#'+modalname).modal('show');
		} 

		if($(this).hasClass('_prev')){

			$('#'+modalname).on('shown.bs.modal', function(){
				var previewImg = $('#'+modalname).find('.uploader-preview-img');

				var ratio = (225 / 96); // 255 > 72 

				var w1_96 = previewImg.find('img').data('width'); //print

				// console.log('De afbeelding is '+ w1_96 +'px breed');

				var w1_225 = w1_96 * ratio; //print

				// console.log('De afbeelding op printformaat '+ w1_225 +'px breed');

				var w2_225 = previewImg.width(); //scherm

				// console.log('Er is ruimte voor '+ w2_225 +'px breed');
				
				var ratio2 = w1_225 / w2_225; // print > scherm
				var wprint = w2_225 * ratio;

				//previewImg.find('img').css('transform','scale('+(96/225)+') translateY(-50%) translateX(-50%)');

				

				// console.log('Dat zou op print formaat '+ wprint +'px zijn');

				// console.log('De verhouding scherm > print is '+ ratio2);

				// console.log('De verhouding print > print is '+ (w1_225 / wprint) );

				var w_max = w1_96 / ratio;
				
				// console.log('De afbeelding op scherm '+ w_max * (wprint / w1_225) + 'px breed');

				var w2_96 = w1_96 * ratio2; //scherm

				var w2_w1 = (w2_225 * 100 / w1_225);

				

				var b = w2_96 * w2_225 / w1_225;

				// console.log(ratio, ratio2, w1_96, w1_225, w2_96, w2_225, w2_w1, w_max, b);

				//previewImg.find('img').css('maxWidth', w_max+'px');
			});

			
			//previewImg.css('maxWidth', maxdo);
		/* 	var thisupload = $(this).closest('.upload'),
			width = thisupload.find('._preview-size-block').css('width'),
			height = thisupload.find('._preview-holder').data('ratio');
			$('body').find('._widthblock').css('width', '100%');
			$('body').find('._heightblock').css('paddingBottom',height+'%');

			 */
		}
 
		return false;
	});

	$('._file-upload-toolbox:not(.-loading)').each(function(){
		var button = $(this);
		var fileUploader = new plupload.Uploader({ 
			runtimes : 'html5',
			browse_button : button[0], 
			drop_element : button[0],
			url : FILEHREF+'xhr/upload-order.php',
			multi_selection : false,
			multipart_params : {fields:button.closest('._uploader-container').find(':input').serialize(),id:button.closest('._uploader-container').data('id')},
			filters : {
				max_file_size : '100mb',
				mime_types: [
					{title : "Image files", extensions : "pdf,jpg,jpeg,png,psd"}, // ,gif,ai,eps,bmp,indd
					{title : "Document files", extensions : "pdf"}
				]
			},
			init: {
				PostInit: function() {		
					
				},
				FilesAdded: function(up, files) {
					fileUploader.start();
				},
				UploadProgress: function(up, file) {
					//document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
				},
				BeforeUpload: function(up,file){
					//button.closest('._file-upload-toolbox').addClass('-loading');
					$('._file-upload-toolbox').addClass('-loading');
					//button.closest('._file-upload-toolbox').find('.plus').hide();
					//button.closest('._file-upload-toolbox').find('.spinner').show();

					var fields = button.closest('.upload').find('._uploader-container').find(':input').serialize(),
						id = button.closest('.upload').find('._uploader-container').data('id');

					fileUploader.settings.multipart_params = {fields:fields,id:id};	
				},
				FileUploaded: function(up,file,result){
					// preview uploaded data
					uploadtimer = setInterval(checkUploadStatus, 3000, result.response);
				},
				Error: function(up,error){
					alert(error.message);	
				}
			},
		});
		fileUploader.init();
	});

	$('._file-upload-toolbox-top:not(.-loading)').each(function(){
		var button = $(this);
		var fileUploader = new plupload.Uploader({ 
			runtimes : 'html5',
			browse_button : button[0], 
			drop_element : button[0],
			url : FILEHREF+'xhr/upload-order.php',
			multi_selection : false,
			multipart_params : {fields:$('._uploader-container').first().find(':input').serialize(),id:$('._uploader-container').first().data('id')},
			filters : {
				max_file_size : '100mb',
				mime_types: [
					{title : "Image files", extensions : "pdf,jpg,jpeg,png,psd"}, // ,gif,ai,eps,bmp,indd
					{title : "Document files", extensions : "pdf"}
				]
			},
			init: {
				PostInit: function() {		
					
				},
				FilesAdded: function(up, files) {
					fileUploader.start();
				},
				UploadProgress: function(up, file) {
					//document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
				},
				BeforeUpload: function(up,file){
					$('._file-upload-toolbox').addClass('-loading');
					//button.closest('._file-upload-toolbox').find('.plus').hide();
					//button.closest('._file-upload-toolbox').find('.spinner').show();
					if($('.upload').first().hasClass('_done')){
						var fields = $('.upload').last().find('._uploader-container').find(':input').serialize(),
						id = $('.upload').last().find('._uploader-container').data('id');
					}else{
						var fields = $('.upload').first().find('._uploader-container').find(':input').serialize(),
						id = $('.upload').first().find('._uploader-container').data('id');
					}


					fileUploader.settings.multipart_params = {fields:fields,id:id};	
				},
				FileUploaded: function(up,file,result){
					// preview uploaded data
					uploadtimer = setInterval(checkUploadStatus, 3000, result.response);
				},
				Error: function(up,error){
					alert(error.message);	
				}
			},
		});
		fileUploader.init();
	});

	$('body').on('change', '._rapport-akkoord-check', function(e){
		var sides = $('._rapport-akkoord-check').length,
			checked_sides = $('._rapport-akkoord-check:checked').length;

		if(sides === checked_sides){
			$('._upload-akkoord').removeClass('isDisabled');
		}else{
			$('._upload-akkoord').addClass('isDisabled');
		}
	});

	$('body').on('click', '._upload-akkoord', function(e){
		e.preventDefault();
		if(!$(this).hasClass('isDisabled') && $(this).hasClass('hasWarnings')){
			$('#akkoordModal').modal('show');
			/* if(!$('._rapport-akkoord-check').is(':checked') && !$('._upload-rapport-notice').is(':visible')){
				$('._upload-rapport-notice').removeClass("d-none");
			}else{ */
				/* $.post("xhr/upload-akkoord.php", $(this).data(), function(data){
					if(data.op_id){
						$('#finalModal').modal('show');
					}
				}, 'JSON'); */
			/* } */
		}else if(!$(this).hasClass('isDisabled')){
			uploadFinished();
		}
	});

	$('body').on('click', '._finish-upload', function(e){
		e.preventDefault();
		uploadFinished();
	});

	$('body').on('click', '._upload-reset', function(e){
		e.preventDefault();

		$.post("xhr/upload-reset.php", $(this).data(), function(data){
			if(data.result){
				window.location.reload();
			}
		}, 'JSON');
	});

	$('body').on('click', '._upload-scale', function(e){
		e.preventDefault();

		var el = $(this).closest('._upload-holder').find('._preview-size-block');

		el.addClass('-loading');
		el.find('.top').prepend('<div class="spinner"><div class="rotate"><div class="mask"><div class="circle"></div></div></div></div>');

		$.post("xhr/upload-scale.php", $(this).data(), function(data){
			if(data.result){
				uploadtimer = setInterval(checkUploadStatus, 3000, data.id);
			}
		}, 'JSON');
	});

	$('body').on('click', '._upload-rotate', function(e){
		e.preventDefault();

		var el = $(this).closest('._upload-holder').find('._preview-size-block');

		el.addClass('-loading');
		el.find('.top').prepend('<div class="spinner"><div class="rotate"><div class="mask"><div class="circle"></div></div></div></div>');

		$.post("xhr/upload-rotate.php", $(this).data(), function(data){
			if(data.result){
				uploadtimer = setInterval(checkUploadStatus, 3000, data.id);
				//window.location.reload();
			}
		}, 'JSON');
	});

	if($('._check-upload-status').length > 0){
		$('._check-upload-status').each(function(i,v){
			setInterval(checkUploadStatus, 3000, $(v).data('id'));
		});
	}

}).fail(function(error){
	throw error;
}); 

$(window).resize(function(){
	"use strict";
	setBestelStappen();	
});

function uploadFinished(){
	var el = $('._upload-akkoord');
	$.post("xhr/upload-akkoord.php", el.data(), function(data){
		if(data.op_id){
			$('#akkoordModal').modal('hide');
			$('#finalModal').modal('show');

			window.close();
			window.opener.location.reload(false);

			window.location.href = data.url;
		}
	}, 'JSON');
}

function setBestelStappen(){   
	"use strict";
	if($('._bestellen-stappen').length > 0){
		// /$('._bestellen-stappen').css('left','auto');
		var el = $('._bestellen-stappen'),    
			elWidth = el.outerWidth(),
			windowWidth = $(window).outerWidth(),
			windowCenter = windowWidth / 2,
			activeStep = el.find('.active'),
			activeStepWidth = activeStep.outerWidth(),
			activeStepWidthCenter = activeStepWidth /2,
			activeStepOffset = activeStep.offset().left,
			stappenOffset = windowCenter - (activeStepOffset+activeStepWidthCenter);
			
		/*if(elWidth > windowWidth){ //scherm kleiner dan crumbsbalk
			$('._bestellen-stappen').css('left',stappenOffset);
		} 	*/
			
		if(activeStepOffset+ activeStepWidth > windowWidth){ //actieve stap past niet op scherm dus plaats in midden
			//$('._bestellen-stappen').css('left',stappenOffset);
			
			$('._bestellen-stappen-holder').animate({scrollLeft: stappenOffset * -1}, 500);;
		} 
	}	 
}

function checkUploadStatus(id){
	$.post("xhr/upload-status.php", {id: id}, function(data){
		if(data.status == 'finished'){
			window.location.reload();
		}else if(data.status == 'failed'){
			$('._upload_error').removeClass('d-none');
			clearInterval(uploadtimer);
		}
	},'JSON');
}
var inputtime,
	calctime,
	menutime,
	scrolltime,
	lastScrollTop = 0,
	searchFocus = '',
	FILEHREF = $('base').attr('href');

$.ready.then(function() {
	"use strict";

	if($('._upload-holder').length >= 1 ){
		if ($('.mobile_upload:visible').length == 1 && $('._upload-holder').length > 1) {
			$('._upload-holder').last().removeClass('d-flex').addClass('d-none');
		}
		calcUploaderSizes(); 
	}

	if($('._blok-file-tns').length > 0){
		var slider = tns({
			container: '._blok-file-tns',
			items: 1, 
			gutter: 40,  
			centre: true, 
			touch: true,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			controls: false,
			loop: false,
		});	
	}

	if($('._marketingblokjes-wrapper').length > 0){
		var slider = tns({
			container: '._marketingblokjes-wrapper',
			items: 1, 
			gutter: 40,  
			centre: true, 
			touch: true,
			mouseDrag: true,
			nav: false,
			controls: true,
			prevButton: '._marketing-slider-prev',
			nextButton: '._marketing-slider-next', 
			loop: false,
			responsive: {
				768: {
					disable: true,
				} 
			}
		});		  
	}

	if($('._alles-op-alles-wrapper').length > 0){
		var slider = tns({
			container: '._alles-op-alles-wrapper',
			items: 2,
			gutter: 20,  
			touch: true, 
			mouseDrag: true,  
			centre: true,
			nav: true, 
			navPosition: 'bottom',
			controls: false,
			prevButton: '._alles-op-alles-slider-prev', 
			nextButton: '._alles-op-alles-slider-next', 
			loop: false, 
			responsive: {
				992: { 
					items: 4,
					controls: true,
					nav: false,
					edgePadding: 0
				}
			}
		});		
	} 
 
	if($('._scherpgeprijsd-wrapper-mobile').length > 0){
		var slider = tns({
			container: '._scherpgeprijsd-wrapper-mobile', 
			items: 2,
			// edgePadding: 0,
			gutter: 15,
			touch: true,
			mouseDrag: true,
			centre: true, 
			nav: true,
			navPosition: 'bottom',
			controls: false,
			loop: false,
			responsive: {
				992: { 
					nav: false,
				}
			}
		});
	}
 
	if($('._scherpgeprijsd-wrapper').length > 0){
		var slider = tns({
			container: '._scherpgeprijsd-wrapper',
			items: 4,
			gutter: 20,
			// edgePadding: 15,
			touch: true, 
			mouseDrag: true, 
			centre: true,
			nav: false,
			navPosition: 'bottom',
			controls: true,
			prevButton: '._scherpgeprijsd-slider-prev',
			nextButton: '._scherpgeprijsd-slider-next',
			loop: false,
		});		
	}

	if($('._categorie-wrapper').length > 0){
		var slider = tns({
			container: '._categorie-wrapper',
			items: 2,
			gutter: 15, 
			// edgePadding: 20, 
			touch: true,
			mouseDrag: true, 
			centre: true,
			nav: false,
			controls: true,
			prevButton: '._categorie-slider-mobile-prev',
			nextButton: '._categorie-slider-mobile-next',
			loop: true, 
			responsive: {
				768: {
					items: 6,
				}
			}
		});		
	}

	if($('._categorie-wrapper-desktop').length > 0){
		var slider = tns({
			container: '._categorie-wrapper-desktop',
			items: 6,
			gutter: 15,
			touch: true,
			mouseDrag: true, 
			centre: true,
			nav: false,
			controls: true,
			prevButton: '._categorie-slider-desktop-prev',
			nextButton: '._categorie-slider-desktop-next',
			loop: true,
			
		});		
	}

	if($('._hardloper-wrapper-mobile').length > 0){
		var slider = tns({ 
			container: '._hardloper-wrapper-mobile',
			items: 2, 
			gutter: 15, 
			// edgePadding: 20, 
			touch: true,
			mouseDrag: true, 
			centre: true,
			nav: false,
			controls: true,
			prevButton: '._hardloper-slider-mobile-prev', 
			nextButton: '._hardloper-slider-mobile-next',
			loop: true, 
	
		});
	}

	if($('._hardloper-wrapper-desktop').length > 0){
		var slider = tns({ 
			container: '._hardloper-wrapper-desktop',
			items: 6, 
			gutter: 15, 
			touch: true,
			mouseDrag: true, 
			centre: true,
			nav: false,
			controls: true,
			prevButton: '._hardloper-slider-desktop-prev',
			nextButton: '._hardloper-slider-desktop-next',
			loop: true, 
		});	
		
	}

	$('body').on('click', '._show-zijde', function(e){
		e.preventDefault(); 

		var zijde = $(this).data('zijde');

		$('._uploader-zijdes').removeClass('d-flex').addClass('d-none');
		$('._uploader-zijde-'+zijde).addClass('d-flex').removeClass('d-none');
		$(this).addClass('text-underline');
		$(this).siblings('._show-zijde').removeClass('text-underline');
		calcUploaderSizes();
	});

	$('.faq-item:first-child').removeClass('-faq-closed');
	$('.faq-item .circle-plus').addClass('opened');

	$('body').on('click','.faq-item > a',function(e){
		
		// $('.faq-item').not($(this).closest('.faq-item')).addClass('-faq-closed').removeClass('-border');
		// $('.faq-item').not($(this).closest('.faq-item')).find('.circle-plus').removeClass('opened');
		$(this).closest('.faq-item').toggleClass('-faq-closed').toggleClass('-border');
		$(this).closest('.faq-item').find('.circle-plus').toggleClass('opened');
		e.preventDefault();
	});   

	if($('#cookie-bar').length == 1){
        if($('#cookie-bar').find('a[href="'+window.location.href+'"]').length == 0){
			if(getCookie("firstCookie") !== null && window.location.href != getCookie("firstCookie")){
				setCookie("cookies_accepted",2,99);
				$("#cookie-bar").remove();
			}else{
				setCookie("firstCookie",window.location.href,99);
				setCookie("cookies_accepted",1,99);
			}
        }
        $('body').on('click','#_accept-cookies',function(){
            setCookie("cookies_accepted",2,99);
			$("#cookie-bar").remove();
        });
	}

	initDatatables();

	$('[data-toggle="tooltip"]').tooltip();

	if ($('.ismobile:visible').length == 1) {
		$('.collapse').removeClass('show');
		// $('.bestelling').hide();
	}; 

	$("body").on("click","._click-container-sample",function(e){
		var _href = $(this).attr("data-href");

		if(_href != ''){
			window.location.href = _href;
		}
		e.preventDefault();
	});

	$("body").on("click",".collapsetitle",function(){
		$(this).find('.arrow').toggleClass('invisible');
	});
	
	$("body").on("click",".option.list, .option.photo",function(e){
		e.preventDefault();

		var el = $(this).closest('._keuze-stap');
		$(this).toggleClass('d-none');
		$(this).siblings('.option.photo, .option.list').toggleClass('d-none');
		
		el.find('.block').toggleClass('-long');
		el.find('.productblok-head').toggleClass('productblok');

		if ($(this).hasClass('list')){
			el.find('.highlighter').removeClass('ribbon');
			el.find('.productblok-head').removeClass('col-6 col-sm-4 col-md-3 col-lg-6').addClass('col-12 col-lg-6');
		} else { 
			el.find('.productblok-head').removeClass('col-12 col-lg-6').addClass('col-6 col-sm-4 col-md-3 col-lg-6');
			el.find('.highlighter').addClass('ribbon');
		}
	});

	$("body").on("click",".later-leveren",function(e){
		e.preventDefault();

		var column = $(this).data('column'),
			el = $(this).closest('._prijs-tabel');

		if(column == 2 || column == 3){
			if(el.find('.column-' + (column - 1)).length > 0){
				el.find('.column-' + column).removeClass('d-block').addClass('d-none');
				column = column - 1;
				el.find('.column-' + column).addClass('d-block').removeClass('d-none');
			}
		}

		// voor als er dynamische aantallen zijn, testen
		// var aantal = $(this).parent().parent().parent().parent().siblings('.active').find('.aantal-gekozen').html();
		// $('.bestel-nu').addClass('d-none');
		// $(this).parent().parent().parent().parent().siblings('.active').find('.active').removeClass('active').find('.bestel-nu').removeClass('d-none');
	});

	$("body").on("click",".eerder-leveren",function(e){
		e.preventDefault();

		var column = $(this).data('column'),
		el = $(this).closest('._prijs-tabel');

		if(column == 2 || column == 1){
			if(el.find('.column-' + (column + 1)).length > 0){
				el.find('.column-' + column).removeClass('d-block').addClass('d-none');
				column = column + 1;
				el.find('.column-' + column).removeClass('d-none').addClass('d-block');
			}
		}
	});

	if ($('.ismobile:visible').length == 1) {

		if($(".-sticktobottom").length > 0){

			$('.opmerkingen.open-info').addClass('d-none');
			$('.werknemer-icon').addClass('d-none').removeClass('d-block d-lg-none');

			var lastScrollTop = 0;
			$(window).scroll(function(event) {
				var scroll = $(this).scrollTop();

				if(scroll > lastScrollTop) {
					$(".-sticktobottom").fadeOut(500);
					$('.opmerkingen.open-info').removeClass('d-none');
					$('.werknemer-icon').removeClass('d-none').addClass('d-block d-lg-none');
				} else if (scroll == 0){
					$(".-sticktobottom").fadeIn(500);
					$('.opmerkingen.open-info').addClass('d-none');
					$('.werknemer-icon').addClass('d-none').removeClass('d-block d-lg-none');
				}
				lastScrollTop = scroll;
			});
		}

		$('.-mobile').removeClass('d-none');
		$('.-dekstop').addClass('d-none'); 
	}
 
	$("body").on("click",".tabmenu",function(e){
		e.preventDefault();

		$('.tabmenu').removeClass('active');
		$(this).addClass('active');
		var id = $(this).attr('id');
		$('.tabcontent').addClass('d-none');
		$('.' + id).removeClass('d-none');

		

		$('.dropdown-sub:visible').hide('slide', {direction: 'left'}, 250);
		$('.dropdown-menu:visible').hide('slide', {direction: 'left'}, 250);
		$('.product-menu:visible').hide('slide', {direction: 'left'}, 250);
		setTimeout(function(){
			$('.dark-bg').addClass('invisible');
			
		},125);

		setTimeout(function(){
			scrollTo($('.producten-overzicht:visible'));
		},200);
	});


	$("body").on("click",".show-more",function(e){
		e.preventDefault();
		$('.-desktop .blok').fadeIn(400);

		$(this).removeClass('d-lg-inline');

	});

	$('.slider').owlCarousel({
		loop:false,
		margin:20,
		nav:true,
		dots: true,
		responsive:{
			0:{
				items:1,
				nav:false
			},
			600:{
				items:2,
				nav:false
			},
			1000:{
				items:4
			}
		},
		navText : ['<i class="far fa-chevron-left"></i>','<i class="far fa-chevron-right"></i>']
		}
	); 

	$('.slider-werkwijze').owlCarousel({
		loop:false,
		margin:20,
		nav:true,
		dots: true,
		responsive:{
			0:{
				items:1,
				nav:false
			}
		},
		navText : ['<i class="far fa-chevron-left"></i>','<i class="far fa-chevron-right"></i>']
		}
	); 

	$('.slider-klanten').owlCarousel({
		loop:false,
		nav:true,
		dots: true,
		responsive:{
			0:{
				items:1,
				nav:true,
				dots:false
			},
			600:{
				items:2,
				nav:false
			},
			800:{
				items:3,
				nav:false
			},
			1000:{
				items:4,
				nav:false
			},
			1280:{
				items:6
			}
		},
		navText : ['<i class="far fa-chevron-left"></i>','<i class="far fa-chevron-right"></i>']
		}
	); 

	$('.printpagina-slider').owlCarousel({
		loop:false, 
		nav:true,
		dots: false,
		responsive:{
			0:{
				items:1,
				nav:true,
				dots:false
			},
			600:{
				items:2,
				nav:true,
				dots:false
			},
			800:{
				items:3,
				nav:true,
				dots:false
			},
			1000:{
				items:4,
				nav:false,
				dots:false
			},
			1280:{
				items:6,
				dots:false,
				nav: false
			}
		},
		navText : ['<i class="far fa-chevron-left"></i>','<i class="far fa-chevron-right"></i>']
		}
	); 

	$('.drukbedrijvers-slider').owlCarousel({
		loop:false,
		margin:10,
		nav:false,
		dots: false,
		responsive:{ 
			0:{
				items:1,
				dots: false,
				nav:true,
			},
			600:{
				items:2,
			},
			1000:{
				items:3
			}
		},
		navText : ['<i class="far fa-chevron-left"></i>','<i class="far fa-chevron-right"></i>']
		}
	); 

	$("body").on("click",".open-info",function(e){
		e.preventDefault();
		$('#infoModal').modal('show');

		$('#infoModal .modal-title').html($(this).data('title'));
		$('#infoModal .content').html($(this).data('tekst'));

		if($(this).data('title') !== ''){
			$('#infoModal .modal-header').show();
			$('#infoModal .body-close').addClass('d-none');
		} else {
			$('#infoModal .modal-header').hide();
			$('#infoModal .body-close').removeClass('d-none');
		}
		 	
	});

	$("body").on("click",".open-img",function(e){
		e.preventDefault();		

		var owl = $('.open-img-slider'),
			items = [],
			el = $(this),
			to = 0;

		owl.css({maxHeight: '500px', overflow: 'hidden'});

		$('.open-img').each(function(i, obj) {
			if($(obj).data('video') != ''){
				items.push('<a class="owl-video" href="' + $(obj).data('video') + '"></a>');
				if(el.data('video') == $(obj).data('video')){
					to = i;
				}
			}else if($(obj).data('img') != ''){
				items.push('<img src="' + $(obj).data('img') + '" alt="'+ $(obj).data('alt') +'">');
				if(el.data('img') == $(obj).data('img')){
					to = i;
				}
			}
		});

		reDoOwl(items, to).done(function(){
			owl.css({maxHeight: 'auto', overflow: 'initial'});
		});
		$('#afbModal').modal('show');
	});

	$('body').on('change', '.toggle-factuuradres', function(){
		if($(this).is(":checked")){
			$(".factuuradres").addClass("hidden");	
		}else{
			$(".factuuradres").removeClass("hidden");
		}
	});
	
	if($('.toggle-factuuradres').length > 0){
		$('.toggle-factuuradres').trigger("change");	
	}
	
	$('body').on('blur', ':input[data-check]', function(){
		
		var el = $(this),
			dataType = $(this).data('check'),
			dataVal = $(this).val();
		
		$.post("xhr/validate.php",{type: dataType, value: dataVal},function(data){
			if(data.result){
				el.parents(".form-group").removeClass(data.error);
				el.parents(".form-group").addClass(data.success);
			}else{
				el.parents(".form-group").addClass(data.error);
				el.parents(".form-group").removeClass(data.success);
			}
		},"JSON");
	});  
	
	$("body").on("click",".cart-add",function(){
		var id = $(this).data("id");

		$.post("xhr/cart-add.php",{id:id},function(){
			loadPartial("navbar-basket");
			loadPartial("cart");
		}); 
	});
	
	$("body").on("click",".cart-subtract",function(){
		var id = $(this).data("id");

		$.post("xhr/cart-subtract.php",{id:id},function(){
			loadPartial("navbar-basket");
			loadPartial("cart");
		}); 
	});
	
	$("body").on("click",".cart-remove",function(){
		var id = $(this).data("id");

		$.post("xhr/cart-remove.php",{id:id},function(){
			loadPartial("navbar-basket");
			loadPartial("cart");
		});
	});
	
	$('body').on('change', '.set-shipping', function(){
		$.post("xhr/set-shipping.php",{land:$(this).val()},function(){
			loadPartial("cart-small");
		});
	});


	$('#navbarDropdown').on('click', function(e) {
		if ($('.ismobile:visible').length == 1) {
			$('body').addClass('no-scroll');
			$('.dark-bg').removeClass('invisible');
		}
	});

	$('body').on('mouseenter', '.dropdown-menu a.dropdown-toggle', function(e) {
		clearTimeout(inputtime);

		var el = $(this);
		dropdownMenu(el,'show');
	});

	$('body').on('mouseenter', '.dropdown-sub', function(){
		clearTimeout(inputtime);
	});

	$('body').on('click', '.dropdown-sub', function(e){
		e.stopPropagation();
	});

	$('.header-nav-toggle').on('click', function(e) {
		e.preventDefault();

		$('body').addClass('modal-open');
		//dropdown-menu
		$('.dropdown-menu:visible').hide('slide', {direction: 'left'}, 250);

		$("[data-menu='" + $(this).data('openmenu') + "']").show( "slide", {direction: 'left'}, 500);

		$('.dark-bg').removeClass('invisible');
		var windowH = $( window ).height();
		$('.subitems').height(windowH - 188 - 20);

	});

	$('body').on('click','.search-mobile',function(e){
		if(window.pageYOffset == 0){
			$('.zoek-mobile-form').toggle();
		}else{
			$('.zoek-mobile-form').show();
			$('html,body').animate({ scrollTop: 0 }, 'slow');
		}
		
		e.preventDefault();
	});

	$('.header-mobile .close').on('click', function(e) {
		$('body').removeClass('modal-open');
		$('.dropdown-sub:visible').hide('slide', {direction: 'left'}, 250);
		$('.dropdown-menu:visible').hide('slide', {direction: 'left'}, 250);
		$('.product-menu:visible').hide('slide', {direction: 'left'}, 250);
		setTimeout(function(){
			$('.dark-bg').addClass('invisible');
		},125);
	}); 

	$('.header-mobile .terug').on('click', function(e) {
		// $(this).parent().parent().parent().show();
		$(this).parent().parent().hide('slide', {direction: 'right'}, 500, function(){
			
		}); 
		e.preventDefault();
	});

	$('.header-mobile .terug-cat').on('click', function(e) {
		var _cat_id = $(this).attr('data-category');
		
		$(this).parent().parent().hide('slide', {direction: 'right'}, 500);
		
		e.preventDefault();
	});

	$("body").off("click", ".show-bestelling").on("click", ".show-bestelling", function (e) {
		e.preventDefault();
		$('.bestellingen-weergeven .icon').removeClass('fa-angle-down').addClass('fa-angle-up');
		$('.bestellingen-weergeven').removeClass('show-bestelling').addClass('hide-bestelling');
		// $(".bestelling").show( "slide", {direction: 'up'}, 500);
		$(".bestelling").addClass('d-flex').removeClass('d-none');
	});

	$("body").off("click", ".hide-bestelling").on("click", ".hide-bestelling", function (e) {
		e.preventDefault(); 
		$('.bestellingen-weergeven .icon').removeClass('fa-angle-up').addClass('fa-angle-down');
		$('.bestellingen-weergeven').removeClass('hide-bestelling').addClass('show-bestelling');
		// $(".bestelling").hide( "slide", {direction: 'up'}, 500);
		$(".bestelling").removeClass('d-flex').addClass('d-none');
	});

	$('.product-nav-toggle').on('click', function(e) {
		var _cat_id = $(this).attr('data-category');
		$('.dark-bg').removeClass('invisible');

		$("[data-menu='1']").show( "slide", {direction: 'left'}, 0);
		$('#show-'+_cat_id).show('slide', {direction: 'left'}, 0); 
		$('.product-menu').show("slide", {direction: 'left'}, 0);

		var windowH = $( window ).height();
		$('.subitems').height(windowH - 188 - 20);
	});

	// $('.product-menu .close').on('click', function(e) {
	// 	$("[data-menu='1']").hide( "slide", {direction: 'left'}, 0);
	// 	$("[data-menu='1'] .dropdown-sub").hide( "slide", {direction: 'left'}, 0);
	// 	e.preventDefault();
	// });

	$("body").on("click",".uitloggen",function(e){
		var _link = $(this).attr('data-link');
		e.preventDefault();
		
		$.post("xhr/handle-form.php",{do: 'uitloggen'},function(obj){
			window.location.href = _link;
		}, "JSON" );
	}); 

	$('.search-field').autocomplete({
		minLength: 2,
		delay: 200, 
		source: 'xhr/zoek.php',
		open: function(){
			searchFocus = '';
			return false;
		},
		focus: function(event, ui){
			searchFocus = ui.item.url;
		},
		select: function( event, ui ) {
			searchFocus = ui.item.url;
			window.location.href = ui.item.url;
		},
		create: function() {
			$(this).data('ui-autocomplete')._renderItem = function( ul, item ) {
				return $( "<li></li>" )
					.data( "item.autocomplete", item )
					.append( "<a href="+ item.url +" class='ac_item'>" + item.titel + "</a>")
					.appendTo( ul );
			};
		} 
	});

	$('body').on('keyup','.search-field',function(e){
		var zoek = $(this).val();
		if(zoek != ''){
			zoek = zoek+'/';	
		}
		if(e.keyCode == 13) {
			if(searchFocus == ''){
				if($('.ac_item').length == 1){
					window.location.href = $('.ac_item').attr('href');
				}else{
					window.location.href = FILEHREF + 'zoeken/' + zoek;
				}
			}else{
				window.location.href = searchFocus;
			}
		}else{}
		e.preventDefault();
	});

	$('body').on('keyup','.search-field-account',function(e){
		var zoek = $(this).val();
		var soort = $(this).data('soort');
		if(zoek.length > 0) {
			$.post("xhr/account-zoek.php",{'term':zoek, 'soort':soort},function(data){
				if(data){
					$('.' + soort + '-overzicht').html(data);
					initDatatables();
				}
			});
		} else {
			$.post("xhr/account-zoek.php",{'term':"", 'soort':soort},function(data){
				if(data){
					$('.' + soort + '-overzicht').html(data);
					initDatatables();
				}
			});
		}
		e.preventDefault();
	}); 

	$("body").on("click",".new-adres",function(e){
		e.preventDefault();
		var el = $(this);

		if ($('.ismobile:visible').length == 1) {
			var scrollheight = 75;
		} else {
			var scrollheight = 140;
		}

		$("html, body").animate({
			scrollTop: $('.addressen-row').offset().top + $('.addressen-row').outerHeight(true) - scrollheight
		}, 1000);
		$('.adres-toevoegen').removeClass('d-none');
		$('.adres-wijzigen').addClass('d-none');
	});

	$("body").on("click",".wijzig-adres",function(e){
		e.preventDefault();

		var el = $(this);
		$('.adres-toevoegen').addClass('d-none');
		$('.adres-wijzigen').removeClass('d-none');

		if ($('.ismobile:visible').length == 1) {
			var scrollheight = 75;
		} else {
			var scrollheight = 140;
		}

		$("html, body").animate({
			scrollTop: $('.addressen-row').offset().top + $('.addressen-row').outerHeight(true) - scrollheight
		}, 1000);
		
		$.post("xhr/handle-form.php",{'id': el.data('id'), 'do': 'adres-wijzigen'},function(obj){
			if(obj){

				$('.form.adres.wijzigen input[name=bedrijfsnaam]').val(obj[0].bedrijfsnaam);
				$('.form.adres.wijzigen input[name=voornaam]').val(obj[0].voornaam);
				$('.form.adres.wijzigen input[name=tussenvoegsel]').val(obj[0].tussenvoegsel);
				$('.form.adres.wijzigen input[name=achternaam]').val(obj[0].achternaam);
				$('.form.adres.wijzigen input[name=postcode]').val(obj[0].postcode);
				$('.form.adres.wijzigen input[name=plaats]').val(obj[0].plaats);
				$('.form.adres.wijzigen input[name=adres]').val(obj[0].adres);
				$('.form.adres.wijzigen input[name=huisnummer]').val(obj[0].huisnummer);
				$('.form.adres.wijzigen input[name=toevoeging]').val(obj[0].huisnummer_extra);
				$('.form.adres.wijzigen input[name=telefoon]').val(obj[0].telefoon);
				$('.form.adres.wijzigen input[name=adres_id]').val(obj[0].adres_id);
				$('.form.adres.wijzigen select[name=land]').val(obj[0].land);

				$('.form.adres.wijzigen .adres-ingevuld').removeClass('d-none'); 
				$('.form.adres.wijzigen .adresstraat').html(obj[0].adres + ', ' +obj[0].plaats);
			}
		}, "JSON" );
	});

	$('body').on('keyup', '.form input[name=postcodeL], .form input[name=postcodeF]', function(e){
		clearTimeout(inputtime);
		var el = $(this);
		//if(el.val().length >= 4){
			inputtime = setTimeout(function() {
				lookup(el, '._adres-blok'); 
			},300);
		//}
	});

	$('body').on('keyup', '.form input[name=postcode]', function(e){
		clearTimeout(inputtime);
		var el = $(this);
		//if($(this).val().length >= 4){
			inputtime = setTimeout(function() {
				lookup(el, '.form'); 
			},300);
		//}
	});

	$("body").on("click",".adres-opslaan",function(e){
		e.preventDefault();
		var form = $(this).parent().parent().parent();
		
		$.post("xhr/handle-form.php",form.serialize(),function(obj){
			if(obj){
				$.each(obj.classes,function(el,c){
					form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
				}); 

				if (obj.ok == 1){
					$('.wachtwoord .alert-success').removeClass('d-none').html(obj.success);
					$('.wachtwoord .alert-danger').addClass('d-none');
					location.reload();
				} else {
					$('.wachtwoord .alert-danger').removeClass('d-none').html(obj.error);
					$('.wachtwoord .alert-success').addClass('d-none');
				}
			}
		}, "JSON" );
	}); 

	$("body").on("click",".gegevens-opslaan",function(e){
		e.preventDefault();
		var form = $('.gegevens'); 
		
		$.post("xhr/handle-form.php",form.serialize(),function(obj){
			if(obj){

				$.each(obj.classes,function(el,c){
					form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
				}); 
 
				if (obj.ok == 1){
					$('.gegevens .alert-success').removeClass('d-none').html(obj.success);
					$('.gegevens .alert-danger').addClass('d-none');
				} else {
					$('.gegevens .alert-danger').removeClass('d-none').html(obj.error);
					$('.gegevens .alert-success').addClass('d-none');
				}
			}
		}, "JSON" );
	});

	$("body").on("click",".sluiten-adres",function(e){
		e.preventDefault();
		$('.adres-wijzigen').addClass('d-none');
		$('.adres-toevoegen').addClass('d-none');
	});


	$("body").on("click",".adres-verwijderen",function(e){
		e.preventDefault();
		$('#verwijderModal').modal('show');
	});

	$("body").on("click",".adres-verwijderen-bevestigd",function(e){
		e.preventDefault();
		var thisid = $('.adres-verwijderen').data('id');

		$.post("xhr/handle-form.php",{'do':'adres-verwijderen', 'id': thisid},function(obj){
			if(obj){
				if (obj.ok == 1){
					location.reload();
				}
			}
		}, "JSON" );

	});

	$("body").on("click",".send-form",function(e){
		e.preventDefault();
		var form = $(this).closest('.stuur-form'); 
		var forms = form.serialize();

		if($(this).hasClass('isDisabled')){
			return false;
		}

		$(this).addClass('isDisabled');

		$(form).find('.success').addClass('d-none');
		$(form).find('.warning').addClass('d-none');
		$(form).find('.warning .media-body').html('');

		$.post("xhr/handle-form.php",forms,function(obj){
			if(obj){
				$.each(obj.classes,function(el,c){
					form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
				}); 

				if (obj.ok == 1){
					$(form).find('.success').removeClass('d-none');
					$(form).find('.send-form').addClass('isDisabled');
				}else{
					$(form).find('.warning .media-body').html(obj.error);
					$(form).find('.warning').removeClass('d-none');
					$(form).find('.send-form').removeClass('isDisabled');
				}

				if(typeof obj.dataLayer !== 'undefined'){
					if(typeof obj.redirect !== 'undefined'){
						obj.dataLayer.eventTimeout = 2000;
						obj.dataLayer.eventCallback = function(){
							window.location = obj.redirect.url;
						}
					}

					dataLayer.push(obj.dataLayer);
				}else if(typeof obj.redirect !== 'undefined'){
					window.location.href = obj.redirect.url;
				}
			}
		}, "JSON" );
	});

	// $("body").on("click",".send-form-file",function(e){
	// 	e.preventDefault();
	// 	var form = $('.stuur-form'); 
	// 	var forms = form.serialize();
	// 	var file = $('#bestanden')[0].files[0];

	// 	var formData = new FormData(); 
	// 	formData.append('file', file); 
	// 	formData.append('forms', forms);
		
	// 	$.ajax({ url : $(".stuur-form").attr("action"), type : 'POST', data : formData, processData: false, contentType: false, 
	// 		success : function(obj) { 
	// 			var returnedData = JSON.parse(obj);
	// 			if(returnedData){
	// 				$.each(returnedData.classes,function(el,c){
	// 					form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
	// 				});
	// 				if (returnedData.ok == 1){
	// 					$('.success').removeClass('d-none');
	// 				}
	// 			}
	// 		}
	// 	});
	// });

	$("body").on("click",".send-form2",function(e){
		e.preventDefault();
		var form = $('.stuur-form2'); 

		$.post("xhr/handle-form.php",form.serialize(),function(obj){
			if(obj){
				$.each(obj.classes,function(el,c){
					form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
				}); 

				if (obj.ok == 1){
					$('.stuur-form2'+ ' .success').removeClass('d-none');
				}
			}
		}, "JSON" );
	});

	$("body").off("click", ".show-specificaties").on("click", ".show-specificaties", function (e) {
		e.preventDefault();

		if($(this).closest('.order-line').find('.specificaties-detail').hasClass('d-none')){
			$(this).find('.far').removeClass('fa-chevron-down').addClass('fa-chevron-up');
		} else {
			$(this).find('.far').removeClass('fa-chevron-up').addClass('fa-chevron-down');
		}

		$(this).closest('.order-line').find('.specificaties-detail').toggleClass('d-none');
	});

	$("body").on("click",".submit-newsletter",function(e){
		e.preventDefault();
		var form = $('.newsletter'); 
		var forms = form.serialize();

		$.post("xhr/handle-form.php",forms,function(obj){
			if(obj){
				$.each(obj.classes,function(el,c){
					form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
				}); 

				if (obj.ok == 1){
					$('.newsletter .success').html(obj.notice).removeClass('d-none');
				}
			}
		}, "JSON" );
	});

	$("body").on("click",".submit",function(e){
		e.preventDefault();
		var form = $(this).parent(); 
		var forms = form.serialize();


		if(!form.hasClass('-disabled')){
			form.addClass('-disabled');

			$.post("xhr/handle-form.php",forms,function(obj){
				if(obj){
					$.each(obj.classes,function(el,c){
						form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
					}); 

					if (obj.ok == 1){
						form.find('.success').html(obj.notice).removeClass('d-none');
						if($('.modal-dialog').is(':visible')){
							$('.modal-dialog').closest('.modal').modal('hide');
						}
					}
				}
			}, "JSON").done(function(){
				form.removeClass('-disabled');
			});
		}
	});

	$("body").on('click', '._scroll-to-keuze', function(e){
		e.preventDefault();

		var step = $(this).data('step'),
			target = $('._keuze-stap[data-step="'+step+'"]'); 

			
		
		scrollTo(target);
	});
	
	$('body').on('click', '._reset-all-options', function(e){
		e.preventDefault();
		$.get("xhr/btw-pref.php",function(data){ }, "JSON" );

		$('._attr-option-radio').addClass('_with-reset');
		$('._product-config').addClass('-noscroll');
            
		var target = $('._keuze-stap:first');
		scrollTo(target, function(){
			var el = $('._product-config');	

			el.find('._attr-option-radio').prop("checked", false);
			$('._input-maatwerk-f').val('');
			$('._input-maatwerk-m').val('0');

			$('._attr-option-radio:first').trigger('change');

			var uri = window.location.toString();
			if (uri.indexOf("?") > 0) {
				var clean_uri = uri.substring(0, uri.indexOf("?"));
				window.history.replaceState({}, document.title, clean_uri);
			}
		});
	});

	$('body').on('click', '._reset-option', function(e){
		e.preventDefault();

		var el = $(this).closest('._keuze-stap'),
			el2 = $(this).closest('._maat-keuze');		

		if(el.length > 0){
			el.find('._attr-option-radio').addClass('_with-reset');
			el.find('._attr-option-radio').prop("checked", false).trigger('change');
		}

		if(el2.length > 0){
			$('._input-maatwerk-f').val('').trigger('input');
			$('._input-maatwerk-m').val('0').trigger('input');
			$('._prijs-tabel:not(:first)').remove();
		}

		var uri = window.location.toString();
		if (uri.indexOf("?") > 0) {
			var clean_uri = uri.substring(0, uri.indexOf("?"));
			window.history.replaceState({}, document.title, clean_uri);
		}
	});

	$('body').on('click', '._combo-choice', function(e){	 	
		
		e.preventDefault();

		var el = $(this);
		if(!el.hasClass('disabled')){
			el.find('._attr-option-radio').prop("checked", "checked").trigger("change");
		}
	});

	$('body').on('input', '._input-maatwerk-f', function(e){
		clearTimeout(inputtime);
		
		inputtime = setTimeout(function(){
			var maten = telMaatwerkFormaten();
			$('._input-maatwerk-mt').val(maten.totaal);
			$('._prijs-tabel:not(:first)').remove();
		},500);
	});

	$('body').on('input', '._input-maatwerk-m', function(e){
		clearTimeout(inputtime);
		var maten = telMaatwerkMaten();
		inputtime = setTimeout(function(){
			$('._input-maatwerk-mt').val(maten.totaal);
			$('._prijs-tabel:not(:first)').remove();
		},500);
	});

	$('body').on('input', '._input-rel-extra', function(e){
		clearTimeout(inputtime);
		var extras = telExtraGroepen();
		inputtime = setTimeout(function(){
			$('._input-rel-mt').val(extras.totaal);
			$('._prijs-tabel:not(:first)').remove();
		},500);
	});

	$('body').on('focus', '._input-maatwerk-f, ._input-maatwerk-m, ._input-rel-extra', function(e){
		clearTimeout(inputtime);
	});

	$('body').on('change', '._attr-option-radio', function(e){
		clearTimeout(calctime);

		var deferred = new $.Deferred(),
			configurator = $('._product-config'),
			stap_el = $(this).closest('._keuze-stap'),
			cur_stap = stap_el.data('step'),
			optionreset = {};
		e.preventDefault();
		
		//stap_el.find('.number').html('<i class="far fa-sync-alt fa-spin"></i>');
		stap_el.find('.productblok-head').addClass('disabled');

		stap_el.find('.content:not(.-no-autoclose)').slideUp(300);

		$(this).closest('._combo-choice').addClass('active');
		calctime = setTimeout(function(){
			var el = $(this),
				sibs = el.parent().siblings().find('._combo-choice'),
				prod_type = configurator.data('prod-type'),
				prod_id = configurator.data('prod-id'),
				prod_soort = configurator.data('prod-soort'),
				custom_aantal = null,
				combo = {},
				extras = {},
				maatwerk = {};

			sibs.removeClass('active'); 
			el.addClass('active');
			el.find('._attr-option-radio').prop("checked", "checked");

			
			$('._attr-option-radio._with-reset').each(function(i, e){
				optionreset[e.name] = true;
			});

			$('._attr-option-radio').each(function(i, e){
				combo[e.name] = '0';
			});

			$('._attr-option-radio:checked').each(function(i, e){
				combo[e.name] = $(e).val();
			});

			if(prod_type === 'maatwerk'){
				if(prod_soort === 'm2'){
					maatwerk = telMaatwerkFormaten();
				}else if(prod_soort === 'm2label' || prod_soort === 'm2sticker'){
					maatwerk = telMaatwerkRol();
				}else if(prod_soort === 'anders'){
					maatwerk = telMaatwerkMaten();
				}
				if($('._custom-rel-aantal').length > 0){
					custom_aantal = $('._custom-rel-aantal').val();
					
				}
			}

			if(prod_type === 'relatie'){
				extras = telExtraGroepen();
			}

			if(prod_type === 'relatie' && $('._custom-rel-aantal').length > 0){
				custom_aantal = $('._custom-rel-aantal').val();
			}

			if(optionreset.length > 0){
				configurator.addClass('-noscroll');
			}

			var offertetonen = false;
			$.post("xhr/save-config.php", {product: prod_id, type: prod_type, soort: prod_soort, c: combo, m: maatwerk, x: extras, custom_aantal: custom_aantal, offertetonen: offertetonen, optionreset: optionreset}, function(data){
				
			}).done(function(data){ 
				deferred.resolve(true);
			});
		},300);
		
		deferred.promise().done(function(){
			if(configurator.hasClass('-noscroll')){
				loadPartial("product-configurator").done(function(){
					if($('._maatwerkcustomprijs-scrollto').length > 0){ 
						scrollTo($('._maatwerkcustomprijs-scrollto'));  
					}
					eersteProductKeuzeActive();
				});
			}else{  
				for (var i = 1; i < $('div[data-step]').length; i++) {
					var target = $('div[data-step="'+(cur_stap+i)+'"]');
					if(!target.hasClass("-done")){
						scrollTo(target, loadPartial("product-configurator").done(function(){
							eersteProductKeuzeActive();
						}));
						break;
					}
				}
			}
		});
	});
	
	
	$('body').on('input', '._custom-rel-aantal', function(e){ 
		var configurator = $('._product-config');
		clearTimeout(calctime);
		calctime = setTimeout(function(){ 
			configurator.addClass('-noscroll');

			$('._attr-option-radio:last').trigger('change');
			
		},300);
		
	});

	$('body').on("mouseenter", "._select-combo:not(.active)", function(){
		$('._select-combo.active').addClass('-hide-active').removeClass('active');
		$('._select-combo.-hide-active').closest('.keuze').find('._aantal-gekozen').removeClass('active');
	});

	$('body').on("mouseenter", "._first-active.rij", function(){
		$(this).closest('.keuze').find('._aantal-gekozen').addClass("active");
	});

	$('body').on("mouseleave", "._first-active.rij", function(){
		$("._aantal-gekozen").removeClass("active");
	});
	
	eersteProductKeuzeActive();

	$('body').on("mouseleave", "._select-combo", function(){
		$('._select-combo.-hide-active').closest('.keuze').find('._aantal-gekozen').addClass('active').removeClass('-hide-active');
		$('._select-combo.-hide-active').addClass('active').removeClass('-hide-active');
	});
	
	$("body").on("click","._select-combo",function(e){
		e.preventDefault();
		var el = $(this),
			configurator = $('._product-config'),
			prod_type = configurator.data('prod-type'),
			prod_id = configurator.data('prod-id'),
			prod_soort = configurator.data('prod-soort'),
			combo = {},
			maatwerk = {}, 
			groep = el.data('groep'),
			aantal = el.data('aantal'),
			aantal_cart = el.data('aantal-cart'),
			prijs = el.data('prijs');

		if(!el.hasClass('disabled')){
			$('.keuze').removeClass('active');
			$('.rij').removeClass('active');

			$(this).parent().addClass('active');
			$(this).addClass('active');

			$('.bestel-nu').addClass('d-none');
			$(this).find('.bestel-nu').removeClass('d-none');

			if(prod_type === 'maatwerk'){
				if(prod_soort === 'm2'){
					maatwerk = telMaatwerkFormaten();
					maatwerk.aantal_to_cart = aantal_cart;
				}else if(prod_soort === 'm2label' || prod_soort === 'm2sticker'){
					maatwerk = telMaatwerkRol();
				}else if(prod_soort === 'anders'){
					maatwerk = telMaatwerkMaten();
				}
			}

			$('._attr-option-radio:checked').each(function(i, e){
				combo[e.name] = $(e).val();
			});

			$.post("xhr/save-combo.php", {product: prod_id, type: prod_type, soort: prod_soort, c: combo, m: maatwerk, prijs: prijs, groep: groep, aantal: aantal}, function(data){

			}).done(function(data){
				loadPartial("product-configurator");
				setTimeout(function(){
					scrollTo($('._final-product'));
				},500);
			});
		}
	});

	$("body").on('click','._bereken-maatwerk',function(e){
		e.preventDefault();

		var el = $('._prijs-tabel'),
			el2 = $('._maat-keuze'),
			inp_f = $('._input-maatwerk-f'),
			inp_m = $('._input-maatwerk-m'),
			inp_rol = $('._input-maatwerk-rol'),
			config = $('._product-config'),
			inp_errors = 0;

		if(inp_f.length > 0){
			$.each(inp_f, function(i, v){
				if($(this).val() == ''){
					$(this).addClass('-error');
					inp_errors++;
				}
			});
		}

		if(inp_m.length > 0){
			$.each(inp_m, function(i, v){
				if($(this).val() == ''){
					$(this).addClass('-error');
					inp_errors++;
				}
			});
		}

		if(inp_rol.length > 0){
			$.each(inp_rol, function(i, v){
				if($(this).val() == ''){
					$(this).addClass('-error');
					inp_errors++;
				}
			});
		}

		if(inp_errors == 0){
			el2.find('.number').html('<i class="far fa-sync-alt fa-spin"></i>');
			el.find('.aantallen-kiezen').remove();
			config.addClass('-noscroll');
			scrollTo($('._prijs-tabel:visible').first(), function(){
				$('._attr-option-radio:first').trigger('change');
			});
		}
	});

	$("body").on('click','._bereken-rel-extra',function(e){
		e.preventDefault();

		var el = $('._prijs-tabel'),
			el2 = $('._extra-keuze'),
			inp = $('._input-rel-extra'),
			config = $('._product-config'),
			inp_errors = 0;

		if(inp.length > 0){
			$.each(inp, function(i, v){
				if($(this).val() == ''){
					$(this).addClass('-error');
					inp_errors++;
				}
			});
		}

		if(inp_errors == 0){
			el2.find('.number').html('<i class="far fa-sync-alt fa-spin"></i>');
			el.find('.aantallen-kiezen').remove();
			config.addClass('-noscroll');
			scrollTo($('._prijs-tabel:visible').first(), function(){
				$('._attr-option-radio:first').trigger('change');
			});
		}
	});

	$("body").on('click','._view-cat-producten',function(e){
		e.preventDefault();

		$('.product-nav').find('.nav-link:first').trigger('click');

		scrollTo($('._product-overzicht:visible').first());
	});


	$("body").on('click','._product-samenstellen',function(e){
		e.preventDefault();

		$('.product-nav').find('.nav-link:first').trigger('click');

		scrollTo($('._keuze-stap:first'));
	});

	$("body").on('click','._product-to-cart',function(e){
		e.preventDefault();
		$.post("xhr/save-cart.php", {}, function(data){
			if(data.success){
				window.location.href = data.winkelmand;
			}else{
				window.location.reload();
			}
		},'JSON');
	});

	$("body").on('click','._product-to-offerte',function(e){
		e.preventDefault();
		$('#offerteModal').modal('show');
	});

	$("body").on('click','._cart-action',function(e){
		e.preventDefault();
		var el = $(this),
			val = el.val(),
			action = el.data('action'),
			key = el.data('key');
		
		$.post("xhr/cart-action.php", {action: action, key: key, val: val}, function(data){
			if(data.success){
				window.location.reload();
			}
		},'JSON');
	}); 

	// $('body').on('click', '._hebjeeen-kortings-code', function(e){
	// 	$("._kortingscode").toggleClass('d-none'); 
	// });
	
	$("._order-korting-input").on('keydown', function(e){
		if(e.keyCode == 13) {
			$('._order-korting-activeer').trigger('click');
		}
	});

	$("body").on('click', '._order-korting-activeer', function(e){
		e.preventDefault();

		var el = $('._order-korting-input'),
			btn = $(this),
			code = el.val();

		$.post("xhr/check-korting.php",{kortingscode: code},function(data){

			if(data.ok === '1'){
				window.location.reload();
			}else if(data.ok === '0'){ 
				btn.html(data.toonkorting);
				el.closest('._kortingscode').addClass('-korting-error');
				$('._kortingscode-error').removeClass('d-none'); 
			}
		},"JSON");
	});

	$("body").on('click', '._order-tegoed-activeer', function(e){
		e.preventDefault();

		var el = $(this),
			btn = $(this);

		$.post("xhr/check-tegoed.php",{},function(data){

			if(data.ok === '1'){
				window.location.reload();
			}else if(data.ok === '0'){ 
				btn.html(data.toonkorting);
				el.closest('._kortingscode').addClass('-korting-error');
			}
		},"JSON");
	});
 
	$('body').on('click', '._confirm-order', function(e){
		e.preventDefault();
		if(!$(this).hasClass('-disabled isDisabled')){
			$(this).addClass('-disabled isDisabled');
			$.post("xhr/confirm-order.php",{action: 'confirm'},function(data){
				if(data.success){
					window.location.href = data.redirect;
				}
			}, "JSON");
		}
	});

	$('body').on('change', '._kies-betaal-methode', function(e){
		var betaalmethode = $(this).val();
		$.post("xhr/update-order.php",{name: 'betaalmethode', value: betaalmethode},function(data){
			if(data.success){

				//if(betaalmethode != 'biller'){
					$('._confirm-order').removeClass("-disabled isDisabled");
					$('._confirm-order').prop("disabled", false);
				//}
			}else{
				$('._confirm-order').addClass("-disabled isDisabled");
				$('._confirm-order').prop("disabled", true);
			}
		},"JSON");

		$('._extra-biller-fields').hide();
		if(betaalmethode == 'biller'){
			$('._extra-biller-fields').show();
		}
	});

	$('body').on('input', '._extra-biller-fields :input', function(e){
		clearTimeout(inputtime);

		var name = $(this).attr('name');
		var value = $(this).val();

		inputtime = setTimeout(function(){
			$.post("xhr/update-order.php",{name: name, value: value},function(data){
				//$('._confirm-order').removeClass("-disabled isDisabled");
				//$('._confirm-order').prop("disabled", false);
			},"JSON");
		},300);
	});

	$('body').on('input', '._set-betaal-opmerking', function(e){
		clearTimeout(inputtime);

		var opmerking = $(this).val();

		inputtime = setTimeout(function(){
			$.post("xhr/update-order.php",{name: 'opmerking', value: opmerking},function(data){
				
			},"JSON");
		},300);
	});

	$('body').on('click', '._del-order-file', function(e){
		e.preventDefault();

		var el = $(this),
			key = el.data('id');

		$.post("xhr/update-order.php",{name: 'order-file', value: key},function(data){
			if(data.success){
				$('._my_file_uploader-container[data-id="'+el.closest('.order-line').data('id')+'"]').show();

				el.closest('._upload-line').remove();				
			}
		},"JSON");
	});

	$('body').on('click', '._offerte-activeren', function(e){
		e.preventDefault();

		var el = $(this),
			key = el.data('key');

		$.post("xhr/handle-offerte.php",{type: 'activeren', value: key},function(data){
			if(data.success){
				window.location.href = data.redirect;
			}
		},"JSON");
	});

	$('body').on('click', '._offerte-aanvragen', function(e){
		e.preventDefault();

		var el = $(this),
			key = el.data('key');

		$.post("xhr/handle-offerte.php",{type: 'aanvragen', value: key},function(data){
			if(data.success){
				el.replaceWith('<a href="#"><i class="far fa-check"></i></a>');
			}
		},"JSON");
	});

	$('.modal').on('show.bs.modal', function () {
        if ($(document).height() > $(window).height()) {
            // no-scroll
            $('header-bar').addClass("modal-open-noscroll");
        }
        else {
            $('header-bar').removeClass("modal-open-noscroll");
        }
    });
    $('.modal').on('hide.bs.modal', function () {
        $('header-bar').removeClass("modal-open-noscroll");
	});
	
	$('body').on('mouseenter', '.header-sub .nav-item', function(){
		clearTimeout(inputtime);
		var el = $(this).find('.nav-link'),
			dd = $(this).find('.dropdown-menu');

		if(!dd.hasClass('show')){
			inputtime = setTimeout(function(){
				el.trigger('click');
			},200);
		}

		//$('.header-sub').css('position', 'absolute');
		//$('.header-sub').css('top', $(window).scrollTop() + $('.header-sub').offset().top);
	});

	$('body').on('mouseleave', '.header-sub .nav-item', function(){
		clearTimeout(inputtime);
		var el = $(this).find('.nav-link'),
			dd = $(this).find('.dropdown-menu');

		if(dd.hasClass('show')){
			inputtime = setTimeout(function(){
				el.trigger('click');
			},300);  
		}

		//$('.header-sub').css('position', 'fixed');
		//$('.header-sub').css('top', $('.header-bar').outerHeight());
	}); 
	

	$('body').on('input', '.-error', function(){
		$(this).removeClass('-error');
	});

	$('body').on('mouseenter', '.dropdown-sub', function(){
		clearTimeout(menutime);
	});

	$('body').on('mouseenter', '.subitems li', function(){
		var img = $(this).find('a').data('foto');
		$(this).closest('.dropdown-sub').find('.menu-preview').children('img').attr('src', img);
	});

	$('body').on('click', '._open-tooltip', function(e){
		e.preventDefault();
		var el = $(this),
			type = $('._product-config').data('prod-type'),
			prod_id = $('._product-config').data('prod-id'),
			id = el.data('id'),
			data = {type: type, id: id, prod_id: prod_id};

		$('body').addClass("modal-open tooltip-active");

		loadPartial('tooltip',data).done(function(){
			$('#tooltipModal').modal().show();
			setTimeout(function(){
				$('._tooltip-owl').owlCarousel({
					loop: true,
					margin:20,
					nav: true,
					dots: false,
					autoHeight: true,
					items: 1,
					video: true, videoHeight: 300,
					navText : ['<i class="far fa-chevron-left"></i>','<i class="far fa-chevron-right"></i>']
					}
				);				
			},100);
		});
	});

	$('body').on('click', '._close-tooltip', function(e){
		e.preventDefault();

		$('div[data-partial=tooltip]').html('');
		$('body').removeClass("modal-open tooltip-active");
	});

	$('body').on('click',  '._tooltip-optie', function(e){
		e.preventDefault();

		var attr_id = $(this).data('id');

		$('._close-tooltip').trigger('click');
		$('body').find('._attr-option-radio[value="'+attr_id+'"]').prop("checked", true).trigger('change');
	});

	$('body').on('change', '._set-offerte-staffel', function(e){
		e.preventDefault();

		var id = $(this).data('offerte'),
			data = {
				'id': id,
				'producten' : []
			};
			
		$('._set-offerte-staffel:checked').each(function(i,v){
			var p_staffel_id = $(v).val(),
				p_product_id = $(v).data('product');

			data.producten.push({
				product_id: p_product_id,
				staffel_id: p_staffel_id
			});
		});
		
		loadPartial("account-offerte-totaal", data);
	});

	$('body').on('click', '._offerte-activeren-staffels', function(e){
		e.preventDefault();

		var id = $(this).data('offerte'),
			formData = {
				'type' : 'activeren-staffels',
				'value': id,
				'producten' : []
			};
			
		$('._set-offerte-staffel:checked').each(function(i,v){
			var p_staffel_id = $(v).val(),
				p_product_id = $(v).data('product');

				formData.producten.push({
				product_id: p_product_id,
				staffel_id: p_staffel_id
			});
		});

		$.post("xhr/handle-offerte.php", formData, function(data){
			if(data.success){
				window.location.href = data.redirect;
			}
		},"JSON");
	});
	
	$('body').on('click', '._show-more-blog', function(e){
		e.preventDefault();

		$('._blog-card.-hidden:lt(6)').removeClass('-hidden');

		if($('._blog-card.-hidden').length == 0){
			$(this).remove();
		}
	});

	$("body").on('click','._studenten_modal',function(e){
		e.preventDefault();
		$('#studentenModal').modal('show');
	});

	$('body').on('click', '._order-herhalen', function(e){
		e.preventDefault();

		var el = $(this),
			key = el.data('key');

		$.post("xhr/handle-order.php",{type: 'herhalen', value: key},function(data){
			if(data.success){
				window.location.href = data.redirect;
			}
		},"JSON");
	});

	$('body').on('click', '._open-uploader', function(e){
		e.preventDefault();
		var src = $(this).attr('href');
		window.open(src, "Uploader");
	});

	// Overrule for default 'jump-to-anchor' behaviour 
	$('a').on('click', function(e){ 
		var anchorRef = $(this).attr('href');
		if(anchorRef.length > 1 && anchorRef.substring(0,4) != 'http'){
			var anchorTarget = $(anchorRef);

			// Scroll to anchor element + push url with anchor tag to history
			if(anchorTarget.length && anchorTarget.is(':visible')){ 
				var targetWithAnchor = window.location.href 
				
				if(targetWithAnchor.indexOf(anchorRef) == -1){
					var targetWithAnchor = window.location.href + anchorRef;
				}

				$('html, body').animate({
					scrollTop: anchorTarget.offset().top - 200 
				}, 300); 
				e.preventDefault(); 
				window.history.pushState({href: targetWithAnchor}, '', targetWithAnchor);
			}
		}
	}); 

}).fail(function(error){
	throw error;
});

function dropdownMenu(_this,_type){
	clearTimeout(menutime);

	menutime = setTimeout(function(){
		if(_type == 'show'){
			$('.dropdown-sub').hide();

			if (!$(_this).next().hasClass('show')) {
				$(_this).parents('.dropdown-menu').first().find('.show').removeClass("show");
			}
			var $subMenu = $(_this).next(".dropdown-menu");
			$subMenu.toggleClass('show');
		
			$(_this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
				$('.dropdown-submenu .show').removeClass("show");
			});

			if($(_this).siblings('.dropdown-sub').length > 0){
				$('.dropdown-menu').removeClass('shadow');
			} else {
				$('.dropdown-menu').addClass('shadow');
				//location.href = $(_this).attr('href');
			}

			//event voor clicken van de categorie item in het menu.
			$(_this).on('click', function(e) {
				location.href = $(_this).attr('href');
			});

			if ($('.ismobile:visible').length == 1) {
				$(_this).siblings('.dropdown-sub').show("slide", {direction: 'right'}, 500);
			} else {
				$(_this).siblings('.dropdown-sub').width($('.header-bar .container').width()-(294 + 20 + 8));
				
				$(_this).siblings('.dropdown-sub').show();
				$(_this).siblings('.dropdown-sub').height($(_this).siblings('.dropdown-sub').find(".subitems").height());
			}

			$('.dropdown-item').removeClass('active');
			$(_this).addClass('active');
		}
	}, 200);
}

function telMaatwerkMaten(){
	var mh = {},
		md = {},
		mt = 0,
		maten  = {
			totaal : 0,
			dames : {
				s : 0,
				m : 0,
				l : 0,
				xl : 0,
				xxl : 0
			},
			heren : {
				s : 0,
				m : 0,
				l : 0,
				xl : 0,
				xxl : 0
			}
		};

	$('._input-maatwerk-m').each(function(i,v){
		var type = $(v).data('name'),
			maat = $(v).data('maat'),
			aantal = parseInt($(v).val()) || 0;

		if(type === 'dames'){
			md[maat] = aantal;
		}else if(type === 'heren'){
			mh[maat] = aantal;
		}

		mt = mt + aantal;
	});

	maten = {'dames' : md, 'heren': mh, 'totaal': mt};

	return maten;
}

function telMaatwerkRol(){
	var b_el = $('._input-maatwerk-rol[name="breedte"]'),
		h_el = $('._input-maatwerk-rol[name="hoogte"]'),
		bleed_el = $('._input-maatwerk-rol[name="bleed"]'),
		totaal_el = $('._input-maatwerk-rol[name="totaal"]'),
		maten  = {
			totaal :  0,
			breedte : 0,
			bleed : 0,
			hoogte : 0
		};

		b_el.val(b_el.val().replace(/[,]+/g, '.'));
		h_el.val(h_el.val().replace(/[,]+/g, '.'));
		b_el.val(b_el.val().replace(/[^0-9\.]+/g, ''));
		h_el.val(h_el.val().replace(/[^0-9\.]+/g, ''));

	maten = {'breedte' : b_el.val(), 'hoogte': h_el.val(), 'bleed': bleed_el.val(), 'totaal': totaal_el.val(), 'aantal_to_cart': 1};

	return maten;
}

function telMaatwerkFormaten(){
	var b_el = $('._input-maatwerk-f[name="breedte"]'),
		h_el = $('._input-maatwerk-f[name="hoogte"]'),
		b_min = b_el.data('min-val'),
		h_min = h_el.data('min-val'),
		maten  = {
			totaal :  0,
			breedte : 0,
			hoogte : 0
		};

		b_el.val(b_el.val().replace(/[,]+/g, '.'));
		h_el.val(h_el.val().replace(/[,]+/g, '.'));
		b_el.val(b_el.val().replace(/[^0-9\.]+/g, ''));
		h_el.val(h_el.val().replace(/[^0-9\.]+/g, ''));

	var breed = parseFloat(b_el.val())/100;
	var hoog = parseFloat(h_el.val())/100;
					
	if(b_el.val() != '' && h_el.val() != ''){
		if(b_el.val() < b_min){
			b_el.val(b_min);		
		}
		
		if(h_el.val() < h_min){
			h_el.val(h_min);		
		}
		var totaal = Math.round(hoog*breed * 1000) / 1000;
	} 

	maten = {'breedte' : b_el.val(), 'hoogte': h_el.val(), 'totaal': totaal, 'aantal_to_cart': 1};

	return maten;
}

function scrollTo(target){
	var windowTop = $(window).scrollTop(),
		targetTop = target.offset().top,
		offset = 0;

	/* if(windowTop > targetTop){
		offset = $('header').outerHeight() + $('.header-sub').outerHeight();
	}else{
		offset = $('header').outerHeight() + $('.header-sub').outerHeight();
	}

	if($('._notification-banner').length > 0){
		offset += $('._notification-banner').outerHeight();
	} */

	if(target.hasClass('_maatwerkcustomprijs-scrollto')){ 
		offset+=150;
	}
	console.log({scrollTop:targetTop-offset});
	$('html, body').stop(true, true).animate({scrollTop:targetTop-offset}, 600, 'swing', arguments[1]);
}

function lookup(el, closest){
	var postcode = el.val(),
		form = el.closest(closest);

	$.post('xhr/get-address.php',{postcode:postcode},function(obj){

		if(obj['ok'] !== 0){ 
			

			form.find('input[name=plaats], ._input-plaats').val(obj['plaats']);
			form.find('input[name=adres], ._input-adres').val(obj['straat']);

			if(obj['plaats'] !== '' && obj['straat'] !== ''){
				form.find('.adres').addClass('d-none');
				form.find('.plaats').addClass('d-none');
				form.find('.adres-ingevuld').removeClass('d-none');
				form.find('.adresstraat').html(obj['straat'] +', ' +obj['plaats']);
			}

		} else {
			form.find('.adres-ingevuld').addClass('d-none');
			form.find('.adresstraat').html('');
			form.find('input[name=plaats], ._input-plaats').val('');
			form.find('input[name=adres], ._input-adres').val('');

			form.find('.adres').removeClass('d-none');
			form.find('.plaats').removeClass('d-none');	
		}
	}, "JSON" );	
}

function loadPartial(partial,data){
	"use strict";

	var deferred = new $.Deferred();

	if(typeof(data) === 'undefined'){data = '';}
	if($("[data-partial='"+partial+"']").length === 1){
		$.get("xhr/load-partial.php?partial="+partial,data,function(html){
			$("[data-partial='"+partial+"']").replaceWith(html);

			if('product-configurator' == partial) {
				var product_gtag = $('._final-product').data('gtag');
				if(product_gtag) {
					dataLayer.push(product_gtag);
				}
			}
		}).done(function(){
			deferred.resolve(true);
		});
	}

	return deferred.promise();
}

function initDatatables(){
	$('#data-table').DataTable({
		"order": [],
		paging: false,
		responsive: {
			details: {
				display: $.fn.dataTable.Responsive.display.childRowImmediate,
				type: ''
			},
			breakpoints: [
				{ name: 'screen-xs',  width: 600 }
			]
		}
	});

	$('#data-table-2').DataTable({
		"order": [],
		paging: false,
		searching: false,
		responsive: {
			details: {
				display: $.fn.dataTable.Responsive.display.childRowImmediate,
				type: ''
			},
			breakpoints: [
				{ name: 'screen-xs',  width: 600 }
			]
		}
	});
}


if($('#modal-newsletter').length == 1){
	setTimeout(function(){
		$('#modal-newsletter').modal('show');
		$.post("xhr/set-sessionnewsletter.php",{news:true},function(){});

		$("body").on("click",".btn-newsletter",function(e){
			var formdata = $('.form-special').serialize();
			var form = $('.form-special');
			$.post("xhr/form-newsletter.php",formdata,function(data){
				if(data.notice.error){
					$(form).find('.alert.alert-danger').removeClass('hidden').html(data.notice.error);
					$(form).find('input[name=email]').addClass(data.class.email);
				}
				if(data.notice.success){
					$('#modal-newsletter').find('.begin').addClass("hidden");
					$('#modal-newsletter').find('.thankyou').removeClass("hidden");
				}
			},"JSON");


			e.preventDefault();
		});
	},$('#modal-newsletter').attr('data-time')); 
}

//owl carousel voor product images
function reDoOwl(items, active){
	var carousel = $('.open-img-slider'),
		content = '',
		width = 0,
		deferred = new $.Deferred();

	carousel.trigger('destroy.owl.carousel'); 
	carousel.find('.owl-stage-outer').children().unwrap();
	carousel.removeClass("owl-center owl-loaded owl-text-select-on");

	for (var i = 0; i < items.length; i++) { 
		content += "<div class=\"item\">" + items[i] + "</div>"
	}
	carousel.html(content);

	carousel.owlCarousel({items:1, dots: false, loop: true, nav: true, video: true, videoWidth: false, videoHeight: false, navText: ['<i class="far fa-chevron-left"></a>', '<i class="far fa-chevron-right"></a>']});
	carousel.trigger('to.owl.carousel', [active, 0]).trigger('refresh.ow.carousel');

	carousel.on('refreshed.owl.carousel', function() {
		deferred.resolve(true);
		width = $('.owl-stage-outer').width();
		$('.owl-video-wrapper').css({height: width});
	});

	return deferred.promise();
}

//keydown bind voor product image owl
$(document).on('keydown', function(eventObject) {
	if($('.open-img-slider').is(':visible')){
		if(eventObject.which==37) {
			$('.owl-prev').trigger('click');
		} else if(eventObject.which==39) {
			$('.owl-next').trigger('click');
		}
	}
});

$(window).on('resize', function(){
	clearTimeout(inputtime);
	inputtime = setTimeout(function(){
		if($('._upload-holder').length == 1 ){
			calcUploaderSizes();
		}
	}, 300);
});

$(window).on('load', function(){
	if($('._product-to-cart').is(':visible')){
		scrollTo($('._final-product'));
	}
});

$(window).scroll(function(event){
	/* clearTimeout(scrolltime);
	var st = $(this).scrollTop();
	scrolltime = setTimeout(function(){
		var el = $('.header-sub'),
			eh = $('.header-bar');

		el.find('.nav-item.dropdown').removeClass('show');
		el.find('.nav-item.dropdown .dropdown-menu').removeClass('show');
		if (st > lastScrollTop){
			el.animate({top: "-"+eh.outerHeight()});
		} else if(el.css('top') ===  "-"+eh.outerHeight()+"px") {
			el.css({top: eh.outerHeight()});
		}
		lastScrollTop = st;
	}, 50); */
 });

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString(),
		path = "path=/";
    document.cookie = cname + "=" + cvalue + "; " + expires + "; " + path;
}

function getCookie(name) {
	var nameEQ = name + "=";
	//alert(document.cookie);
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
	var c = ca[i];
	while (c.charAt(0)==' ') c = c.substring(1);
	if (c.indexOf(nameEQ) != -1) 
			return c.substring(nameEQ.length,c.length);
	}
	return null;
}

function telExtraGroepen(){
	var mt = 0,
		maten = {
			groepen : {},
			totaal : 0
		};

	$('._input-rel-extra').each(function(i,v){
		var extragroep = $(v).data('extragroep'),
			extraoptie = $(v).data('extraoptie'),
			aantal = parseInt($(v).val()) || 0;

		maten.groepen[extragroep] = maten.groepen[extragroep] || {};
		maten.groepen[extragroep][extraoptie] = aantal;
		mt = mt + aantal;
	});

	maten.totaal = mt;

	return maten;
}

function calcUploaderSizes(){
	$('._upload-holder:visible').each(function(i, v){
		var el = $(v),
			preview_holder = el.find('._preview-holder'),
			heightcalc = el.find('.heightcalc'),
			preview_ratio = preview_holder.data('ratio'),
			preview_height = preview_holder.height();
		
		heightcalc.css({paddingBottom: '0'});
		var block_width = (preview_height / preview_ratio) * 100;
		if(block_width > 350 && $('._upload-holder').length > 1){
			var block_width = 350;
		}
		preview_holder.find('._preview-size-block').css({width: block_width+'px'});
		heightcalc.css({paddingBottom: preview_ratio+'%'});
		
		var pixel_hoogte = heightcalc.outerHeight(),
			mm_hoogte = heightcalc.data('hoogte'),
			verhouding = pixel_hoogte / mm_hoogte,
			mm_bleed = heightcalc.data('bleed'),
			pixel_bleed = Math.round(mm_bleed * verhouding);
			$("._calcbleed").css("border", "solid rgba(236,28,36,0.5) "+pixel_bleed+"px"); 

		var mm_marge = heightcalc.data('marge'),
			pixel_marge = Math.round(mm_marge * verhouding);
			
			$("._calcmarge").css("border", "solid rgba(253,197,0,0.5) "+pixel_marge+"px"); 
		var distance = -pixel_bleed + -25;
			$("._lineleft").css("left", ""+distance+"px");  
			$("._linetop").css("top", ""+distance+"px");
	}); 
}

function eersteProductKeuzeActive() { 
	if($(".keuze.active").length < 1){
		$("._prijs-tabel").each(function(i,el){
			$(el).find("._first-active.rij").first().addClass("active");
			$(el).find("._first-active.rij").first().closest('.keuze').find('._aantal-gekozen').addClass("active");
		})
	}
}
   

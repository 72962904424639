
$(window).on('load', function() {
    if($('.ismobile:visible').length == 1 && $('#product-config-title').length == 1){
        var divLoc = $('#product-config-title').offset(),
            offset = divLoc.top - $('.header-fix').outerHeight() - 25;

        if($('._notification-banner').length > 0){
            offset -= $('._notification-banner').outerHeight();
        }

        //$('html, body').animate({scrollTop: offset}, "slow");
    }
});

$.ready.then(function() {

    $('body').on('click', '.btw-toggle a', function(e){
        e.preventDefault();
        
        $(this).toggleClass("bg-secondary-color -active").siblings('a').toggleClass("bg-secondary-color -active");

        $('._prijzen-btw-in').toggle();
        $('._prijzen-btw-ex').toggle();

        $.get("xhr/btw-pref.php", {'pref': $(this).data('show-prijzen-btw')},function(data){
            
        }, "JSON" );
    });
    
    /*var waitForZopim = setInterval(function () {
        if (window.$zopim === undefined || window.$zopim.livechat === undefined) {
            return;
        }
        $zopim(function() {
            $zopim(function() {
                $zopim.livechat.hideAll();
            }); 
        });
        clearInterval(waitForZopim);
    }, 100);*/

    //fix voor collapse in footer
    $(window).resize(function(){
        if($(window).width() <= 576){
            $('.footer-columns .collapsetitle').attr('aria-expanded', false);
            $('.footer-columns .collapse').removeClass('show');
        }else{
            $('.footer-columns .collapsetitle').attr('aria-expanded', true);
            $('.footer-columns .collapse').addClass('show');
        }
    });
     
    $("body").on("click",".kopie-afleveradres",function(e){
        e.preventDefault();
        $(".afleveradres :input._kopie-adres").each(function( index, element ) {
            var value = $(element).val();
            var naam = $(element).attr("name");

            naam = naam.slice(0,-1)
            naam = naam + 'F';

            $('.factuuradres :input[name='+naam+']').val(value);
        });

        $('.form input[name=postcodeF]').trigger('keyup');
    });

    $("body").on("click",".kopie-factuuradres",function(e){
        e.preventDefault();
        if($('._drukbedrijf-adres isDisabled')){
            $('._drukbedrijf-adres').removeClass('isDisabled');
            $('#verstuur-naar-drukbedrijf').prop("checked", false).trigger("change");

        }

        $(".factuuradres :input._kopie-adres").each(function( index, element ) {
            var value = $(element).val();
            var naam = $(element).attr("name");

            naam = naam.slice(0,-1)
            naam = naam + 'L';

            $('.afleveradres :input[name='+naam+']').val(value);
        });

        $('.form input[name=postcodeL]').trigger('keyup');
    });

    $('#verstuur-naar-drukbedrijf').attr('checked' , false);

    $("body").on("click",'#verstuur-naar-drukbedrijf',function(e){
        if($('#verstuur-naar-drukbedrijf').is(':checked')){ 

            $(':input[name="landL"]').prop("checked", "checked").trigger("change");

            $('._drukbedrijf-adres').addClass('isDisabled'); 
            $('._drukbedrijf-adres').val('');
            $.post("xhr/get-conf-adres.php",function(obj){
                if(obj){ 
                    $(':input[name="bedrijfsnaamL"]').val($(':input[name="bedrijfsnaamF"]').val());  
                    $(':input[name="voornaamL"]').val($(':input[name="voornaamF"]').val()); 
                    $(':input[name="achternaamL"]').val($(':input[name="achternaamF"]').val()); 
                    $(':input[name="emailL"]').val($(':input[name="emailF"]').val());
                    $(':input[name="telefoonL"]').val($(':input[name="telefoonF"]').val());
                    $(':input[name="postcodeL"]').val(obj.postcode);
                    $(':input[name="plaatsL"]').val(obj.plaats); 
                    $(':input[name="adresL"]').val(obj.adres);
                    $(':input[name="huisnummerL"]').val(obj.huisnummer);

                    $(':input[name="landL"]').val(obj.land);

                }  
            }, "JSON" );
            $('.form input[name=postcodeL]').trigger('keyup');

        }else{
            $('._drukbedrijf-adres').removeClass('isDisabled');
            $( ".kopie-factuuradres" ).trigger( "click" );
        }
      
    });

    $("body").on("click",".gebruik-voor-order",function(e){
        e.preventDefault();
        var adresID = $(this).data('id');
        var adrestype = $(this).data('soort');

        $.post("xhr/handle-form.php",{'do': 'adres-gebruiken-order', 'id': adresID, 'soort': adrestype},function(obj){
			if(obj){
 
                window.location.href = obj.url;
            }  
        }, "JSON" );
    });

    $("body").on("click",".get-type",function(e){
        e.preventDefault();

        var soort = $(this).data('soort'),
            href = $(this).attr('href'); 

        $.post("xhr/handle-form.php",{'do': 'soort-adres-gebruiken', 'soort': soort},function(obj){
			if(obj){

            }
        }, "JSON" ).done(function(){
           window.location.href = href;
        });;

    });

    $('.js-text-date-toggle').on('focus', function() {
        $(this).attr('type', 'date') }
     );

    $('.js-text-time-toggle').on('focus', function() {
        $(this).attr('type', 'time') }
    );

    $(".inlog-form input").keypress(function (e) {
        if (e.keyCode == 13) {
            login();
        }
    });
    $("body").on("click",".inlog-btn",function(e){
		e.preventDefault(); 
        login();
    });

    function login(){
        var form = $('.inlog-form');

		if($('#login_email').val() !== '' && $('#login_wachtwoord').val() !== ''){
			$.post("xhr/handle-form.php",form.serialize(),function(obj){

				if(obj){
					$.each(obj.classes,function(el,c){
						form.find(":input[name='"+el+"']").addClass(c);
					});

					if(obj.ok == 0){
						$('.inlog-form .alert-danger').removeClass('d-none').html(obj.error);
					}
					if (obj.ok == 1){
						$('.inlog-form .alert-danger').addClass('d-none');
						location.reload();
					}
				}
			}, "JSON" );
		}
    }
    $(".registreer-form input").keypress(function (e) {
        if (e.keyCode == 13) {
            registreer();
        }
    });
    
    $("body").on("click",".registreer-btn",function(e){
		e.preventDefault();
		registreer();
    });
    
    function registreer(){
        var form = $('.registreer-form');
		$.post("xhr/handle-form.php",form.serialize(),function(obj){
			if(obj){
				$.each(obj.classes,function(el,c){
					form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
				});

				if(obj.ok == 0 && obj.error !== undefined){
					$('.registreer-form .alert-danger').removeClass('d-none').html(obj.error);
				} 
				if (obj.ok == 1){
					$('.registreer-form .alert-danger').addClass('d-none');
                    $('.registreer-form .alert-success').removeClass('d-none').html(obj.success);
                    location.reload();
				}
			}
		}, "JSON" );
    }

    $(".wachtwoord-form input").keypress(function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            wachtwoord();
        }
    });

	$("body").on("click",".wachtwoord-btn",function(e){
		e.preventDefault();
		wachtwoord();
	});

    function wachtwoord(){
        var form = $('.wachtwoord-form');
	
		$.post("xhr/handle-form.php",form.serialize(),function(obj){
			if(obj){
				$.each(obj.classes,function(el,c){
					form.find(":input[name='"+el+"']").removeClass('-error').addClass(c);
				});
				if (obj.ok == 1){
					$('.wachtwoord-form .alert-success').removeClass('d-none').html(obj.mssg);	
				}
			}
		}, "JSON" );
    }

    $('.inloggen .input').on("focus", function(){
		$('.inloggen').addClass('active');
		$('.registreren').removeClass('active');
	});

	$('.registreren .input').on("focus", function(){
		$('.registreren').addClass('active');
		$('.inloggen').removeClass('active');
	});

	$('.show-inloggen').on('click', function(e) {
        e.preventDefault();
        $('.wachtwoord-form input').blur();
		$('.inloggen').show();
		$('.wachtwoord').hide();
	});

	$('.show-wachtwoord').on('click', function(e) {
        e.preventDefault();
        $('.inlog-form input').blur();
		$('.inloggen').hide();
		$('.wachtwoord').show();
	});

    if( $('#upload-photo').length > 0){
        uploadFile();
    }
}).fail(function(error){
	throw error;
});  
 
function uploadFile(){
	var options;
	var element =  $('#upload-photo');
	
    options = {multiple: false};
    var zone = new FileDrop('upload-photo', options);

    // Do something when a user chooses or drops a file:
    zone.event('send', function (files) {
        // Depending on browser support files (FileList) might contain multiple items.
        files.each(function (file) {
            // React on successful AJAX upload:
            file.event('done', function (xhr) {
            // 'this' here points to fd.File instance that has triggered the event.
                //console.log('Done uploading ' + this.name + ', response:\n\n' + xhr.responseText);

                 var response = $.parseJSON(xhr.responseText);
		
        		if(typeof(response.ok) !== 'undefined'){
                    element.find('.message').html($('.upload-success').html());
        		} else { //error
        			alert(response.nok);	
        		}
            });

            file.event('progress',function (e, file,current,total) {
                //console.log(Math.round(total.loaded / total.total * 100)+ '%');
                element.find('.message').html((Math.round(total.loaded / total.total * 100)+ '%'));
            });

            // Send the file:
            file.sendTo('xhr/upload-file.php');
        });
    });
    // React on successful iframe fallback upload (this is separate mechanism
    // from proper AJAX upload hence another handler):
    zone.event('iframeDone', function (xhr) {
        console.log('response:\n\n' + xhr.responseText);
    });

   
// 	var fd = element.filedrop(options).on('fdsend',function(e,file){
		
// //		$.each(file, function (i, file2) {
// //			setTimeout(function(){
// //            	file2.sendTo('xhr/upload-photo.php');
// //			},200);
// //        });
// 		file.invoke('sendTo', 'xhr/upload-file.php');
// 		element.addClass('uploading');

// 	}).on('fileprogress',function(e, file,current,total){
// 		element.attr('title',(Math.round(current / total * 100)+ '%'));

// 	}).on('filedone',function(e,file,xhr){
// 		var response = $.parseJSON(xhr.responseText);
		
// 		if(typeof(response.ok) !== 'undefined'){
// 			element.attr('title','');
// 			element.parent().siblings('input.upload-photo-key').val(response.key);
// 			fotoelement.css({'background-image':'url('+response.ok+')'}).removeClass('uploading');
// 		} else { //error
// 			alert(response.nok);	
// 		}

// 	});

// 	element.find('input[type=file]').attr({'accept':'.jpg,.jpeg,.png,.gif'});
 }